import { Link, Head } from '@inertiajs/react';
import { PageProps } from '@/types';
import AppLayout from '@/Layouts/AppLayout';
import React from 'react';
import {TOOL_ITEMS} from "@/Config/Tools";

export default function Home({ auth }: PageProps) {
    const handleImageError = () => {
        document.getElementById('screenshot-container')?.classList.add('!hidden');
        document.getElementById('docs-card')?.classList.add('!row-span-1');
        document.getElementById('docs-card-content')?.classList.add('!flex-row');
        document.getElementById('background')?.classList.add('!hidden');
    };

    const toolItems = TOOL_ITEMS.active;
    const toolItemsNext = TOOL_ITEMS.next;

    return (
        <AppLayout user={auth.user}>
            <Head title="Welcome">
                <meta
                    name="description"
                    content="Your one-stop-shop for free PDF and image conversion, compression, and processing services. Try us out today!"
                />
            </Head>
            <div className="bg-gray-50 text-black/50 dark:bg-black dark:text-white/50">
                <div className="relative min-h-screen selection:bg-[#FF2D20] selection:text-white p-12">
                    <div className="relative w-full max-w-2xl px-6 lg:max-w-7xl flex items-center justify-center">
                        <img
                            src="img/smash.png"
                            alt="Smashfile"
                            className="w-12 h-12 sm:w-16 sm:h-16"
                            onError={handleImageError}
                        />
                        <div className="ml-6">
                            <h2 className="text-xl lg:text-4xl font-bold text-gray-900 dark:text-white">
                                SmashFile: Files served fresh and fast!
                            </h2>
                            <h1 className="text-lg lg:text-2xl text-gray-700 dark:text-gray-300">
                                The file conversion service that allows you to work with your files
                                for free.
                            </h1>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-4 mt-12">
                        {toolItems.map((item, index) => (
                            <Link
                                href={route(`tools.tool`, {
                                    format: item.format,
                                    action: item.action,
                                })}
                                key={index}
                            >
                                <div className=" border rounded-lg p-4 cursor-pointer hover:bg-gray-100 text-gray-700 dark:text-gray-200 dark:hover:text-gray-600">
                                    <div className="text-2xl mb-2"><item.icon className="size-6 mb-4" /></div>
                                    <h3 className="text-lg font-bold mb-2">{item.title}</h3>
                                    <p className="">{item.description}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <p className="p-4 mt-12">Upcomming tools</p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-4 mt-12">
                        {toolItemsNext.map((item, index) => (
                            <div
                                key={index}
                                className="border rounded-lg p-4 cursor-pointer hover:bg-gray-100 text-gray-700 dark:text-gray-200 dark:hover:text-gray-600"
                            >
                                <div className="text-2xl mb-2"><item.icon className="size-6 mb-4" /></div>
                                <h3 className="text-lg font-bold mb-2">{item.title}</h3>
                                <p className="">{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}
